.main {
  display: flex;
  flex-direction: column;
  padding: 8% 0px 0px 8%;
}

.title {
  width: 238px;
  height: 27px;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 27.28px;
  text-align: left;
  color: #b0d136;
  padding-bottom: 3%;
}

.bucketallign {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}

.industry {
  display: flex;
  flex-direction: column;
  width: 17%;
  height: 144px;
  padding: 17px 23px 17px 23px;
  border-radius: 12px;
  background: #161d2f;
  justify-content: space-between;
  margin-bottom: 2%;
  cursor: pointer;
}

.industry:hover {
  background: #212b46;
}

.top {
  display: flex;
  justify-content: space-around;
  padding: 2% 12% 6% 0%;
  border-bottom: 1px solid #6a6e79;
}

.text1 {
  width: 175px;
  height: 20px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.82px;
  text-align: left;
  color: #b1b1b1;
  padding-top: 1.5%;
}

.bottom {
  display: flex;
  justify-content: space-between;
}

.text2 {
  width: 175px;
  height: 20px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.82px;
  text-align: left;
  color: #b1b1b1;
  padding-top: 4%;
}

.numofprojects {
  width: 39px;
  height: 39px;
  background: #0d1322;
  border-radius: 50%;
  color: #b1b1b1;
  text-align: center;
  padding-top: 3%;
}
