header {
  padding: 4px;
  background: #161d2f;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  z-index: 999;
}

header h4 {
  color: #898888;
  margin-top: 12px;
  margin-left: 35px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

header span {
  color: #dbdbdb;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-left: 4px;
}

.martop48 {
  margin-top: 48px;
}

.MuiInputLabel-root.MuiInputLabel-formControl {
  margin: 0px;
}

nav {
  background: #212b46;
  padding: 4px;
  padding-top: 18px;
  position: fixed;
  top: 60px;
  left: 0px;
  min-height: 100vh;
  width: 64px;
  z-index: 99;
}

.martop12 {
  margin-top: 12px;
}

.smp {
  background: #212b46;
}

.df-center,
.df-center-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.df-center-col {
  flex-direction: column;
}

.alignCenter {
  align-items: center;
}

.justifyCenter {
  display: flex;
  justify-content: center;
}

.justifyBetween {
  justify-content: space-between;
}

.alignEnd {
  align-items: end;
}

.fixing th,
.fixing td {
  border: 1px solid #212b46;
}

.fixing .smp th {
  text-align: center !important;
}

.fa-filter {
  font-size: 12px;
  color: #6a6e79;
  cursor: pointer;
}

.size32 {
  /* width: 24px; */
  height: auto;
}

.cur {
  cursor: pointer;
}

.sidebardata {
  font-size: 10px;
  color: #ffffff;
  margin-top: 4px;
  text-align: center;
}

.eachsidebar {
  margin-bottom: 22px;
}

.mllogo {
  padding-left: 6px;
}

.fullsidebarboth:hover .sidebarnotactive {
  display: none;
}

.sidebaractive {
  display: none;
}

.fullsidebarboth:hover .sidebaractive {
  display: flex;
}

.sidebaractive h6,
.green {
  color: #b0d136;
}

.dropdown-menu {
  --bs-dropdown-min-width: 5rem !important;
}

.middlepart {
  padding: 60px 0px 0px 64px;
}

.pluginright h4 {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
  margin: 0px;
  margin-top: 4px;
}

.padding-inside {
  padding: 70px 0px 0px 120px;
}

.allpro-padding {
  padding: 12px 0px 0px 12px;
}

.pad24 {
  padding: 24px;
}

.allpipedropbox {
  padding: 40px 25px 0px 25px;
  margin-bottom: 20px;
}

.recenthome {
  margin-bottom: 22px;
}

.recenthome h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  margin: 0px;
}

p {
  margin: 0px;
}

.recenthome p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
  color: #525252;
  margin: 0px;
  margin-left: 15px;
  cursor: pointer;
  padding-bottom: 1px;
  border-bottom: 1px solid #525252;
}

.secondtable {
  margin-top: 80px;
  flex-direction: column;
  padding-right: 85px;
}

.hometable thead tr {
  background: #212b46;
}

.hometable thead td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 19px;
  color: #ffffff;
  padding: 8px 16px;
}

.hometable tbody td {
  padding: 8px 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 19px;
  color: #6a6e79;
}

.allprojects .searchinput {
  background: #0d1322;

  border-radius: 8px;
  outline: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  padding: 8px 20px;
  width: 350px;
  height: 36px;
  border: 1px solid #2b2d32;
}

.assigned {
  margin-top: 36px;
}

.assigned1 th,
.assigned1 td {
  height: 34px;
  font-size: 14px;
}

.assigned1 span {
  margin-left: 10px;
}

.assignedTh {
  padding-left: 4%;
}

.allprojects .searchinput::-webkit-input-placeholder {
  color: #ffffff;
}

.css-fvipm8.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.createbtn {
  background: #161d2f;
  border-radius: 4px;
  color: #b1b1b1;
  border: 0px;
  padding: 8px 16px;
  margin-left: 14px;
  margin-top: 33.5px;
  height: 36px;
}

.allprojects .fa-magnifying-glass {
  margin-left: -34px;
  color: #ffffff;
  width: 12px;
  height: 12px;
}

.w68 {
  width: 68%;
}

.w100 {
  width: 100% !important;
}

.allprojects .head {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}

.mypro {
  margin: 0px;
  margin-left: 12px;
  color: #ffffff;
}

.assignpro {
  margin: 0px;
  margin-left: 32px;
  color: #ffffff;
}

.loadmore {
  background: #212b46;
  border-radius: 3px;
  padding: 8px 16px;
  border: 0px;
  color: #ffffff;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: #ffffff !important;
}

.width50_reg {
  width: 50% !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  /* background: #070d19 !important; */
  color: #b0d136 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background: #b0d136 !important;
}

/* .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: #070d19 !important;
} */

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: #0f131b !important;
}

.p-tabview .p-tabview-nav {
  background: #0f131b !important;
  border: 0px !important;
}

.pipes1 .p-tabview .p-tabview-nav,
.pipes1 .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: #212b46 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-radius: 0px !important;
}

.p-tabview .p-tabview-panels {
  color: #ffffff !important;
  background: #070d19 !important;
}

.card {
  border: 0px !important;
  background: #070d19 !important;
}

button {
  background: transparent;
  border: none;
  outline: none;
}

.martop32 {
  margin-top: 32px;
}

.inputpipeline {
  padding: 6px 22px;
  color: #6a6e79;
  background: #0d1322;
  width: 100%;
  border-radius: 4px;
  height: 47px;
}

.pipelinebtn {
  padding: 8px 16px;
  background: #212b46;
  border: 0px;
  height: 40px;
  color: #ffffff;
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 3px;
}

.clearfilter {
  background: #161d2f;
}

.w70 {
  width: 70%;
}

.pipelinebox {
  margin-top: 32px;
}

.p-tabview .p-tabview-panels {
  padding-left: 0px !important;
}

.pipes {
  height: 40vh;
  display: flex;
  overflow-x: scroll;
  padding: 50px 42px 34px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5a5a5a;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5a5a5a;
}

.logs {
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #6a6e79;
  padding: 0px 0px 0px 30px;
}

.artifacts {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #3c3b3b;
  border-radius: 16px;
  padding: 50px 20px;
  margin-top: 32px;
}

.artifacts button {
  background: #212b46;
  border-radius: 3px;
  color: #ffffff;
  padding: 8px 28px;
  border: 0px;
  outline: 0px;
}

.artifacts p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #6a6e79;
  margin: 0px;
  margin-top: 24px;
}

.artifacts-filename {
  margin: 0px;
  margin-top: 4px;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #525252;
}

.options {
  position: absolute !important;
  top: -50% !important;
  right: 4%;
  background: #212b46 !important;
  color: #ffffff !important;
  margin: 0px;
  padding: 0px;
}

.github {
  margin-left: 18px;
}

.samplelook {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #cfcfcf;
  padding: 12px;
  background: #0d1322;
  border-radius: 14px 13px 9px 0px;
  margin: 0px;
}

.success {
  color: #b0d136 !important;
}

.failure {
  color: #ef1b2e !important;
}

.running {
  color: #0e8ebe !important;
}

.options li {
  cursor: pointer;
  padding: 8px 16px;
  border-bottom: 1px solid #070d19;
  color: #b1b1b1;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  background: #212b46;
}

.options li:hover {
  background: #161d2f !important;
}

.dropdown-menu.show {
  padding: 0px;
}

.options li span {
  margin-left: 12px;
}

.options li i {
  padding: 0px 16px 0px 0px;
}

.datascience {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  color: #ffffff;
}

.allpipeline td {
  color: #070d19;
}

.sample td {
  color: #ffffff;
}

.fixedsize {
  height: 71vh;
  overflow: scroll;
  margin-top: 24px;
}

.fixing {
  position: sticky;
  position: -webkit-sticky;
  top: -3px;
  left: 0px;
  z-index: 9;
}

.mlimageabs {
  position: absolute;
  top: 0px;
  text-align: center;
  width: 100%;
  line-height: 60px;
}

.mlimageabs img {
  width: 8%;
}

.hometable tbody tr {
  border-bottom: 1px solid rgba(82, 82, 82, 0.42);
}

.w33 {
  width: 33.33%;
}

.newmllogo {
  width: 28%;
}

.dropdown-menu.show {
  background: #070d19;
}

.allpipelines h2 {
  padding: 18px 18px 18px 28px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #b0d136;
  margin: 0px;
  width: 100%;
  background: #0f131b;
  position: fixed;
  z-index: 9;
}

.serving h2 {
  padding: 18px 18px 18px 28px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #b0d136;
  margin: 0px;
  width: 100%;
  background: #0f131b;
}

.editingcolor {
  background: #2b2d32 !important;
}

.equalwidth .demo-simple-select {
  /* width: 218px; */
  width: 50%;
  height: 37px;
  background: #161d2f;
}

.allpipecheckbox {
  margin-right: 32px !important;
}

.p-dropdown {
  background: #232025 !important;
  color: #6c757d !important;
  border: none !important;
  outline: none !important;
}

.p-inputtext,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: #ffffff !important;
}

.allpipelines .btnpipeline i {
  margin-right: 8px;
}

.mar-right25 {
  margin-right: 25px;
}

.allpipelines .btnpipeline {
  padding: 8px 28px;
  border-radius: 3px;
  color: #ffffff;
  border: 0px;
  outline: 0px;
  margin-left: 12px;
  width: max-content;
}

.allpipelines .w-half {
  width: 40% !important;
}

.allpipelines .whalf {
  width: 60%;
}

.w-half {
  margin: 0px !important;
}

.allpipelines .btnpipeline {
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.allpipelines .btnpipeline button {
  background: transparent;
  color: #ffffff;
  border: 0px;
  outline: 0px;
  padding: 0px;
}

input[type="checkbox"] {
  accent-color: #b0d136;
}

.allpipelines table th span {
  margin-left: 8px;
}

.allpipelines table th {
  color: #ffffff;
}

.allpipelines table td {
  font-weight: 400;
  color: #6a6e79;
}

table th,
table td {
  padding: 8px 16px;
  font-style: normal;
  font-size: 14px !important;
  line-height: 19px;
  font-weight: 400;
  height: 47px;
}

table tr {
  border-bottom: 1px solid rgba(82, 82, 82, 0.42);
}

.p-dropdown-panel .p-dropdown-items {
  background: #161d2f !important;
  color: #ffffff;
}

.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  /* background: #161D2F !important;
    opacity: 0.8 !important; */
  color: #ffffff !important;
  background: #212b46 !important;
  padding: 6px !important;
  font-size: 12px;
}

.overflows {
  width: 100%;
  overflow: scroll;
  height: 76vh;
}

.servingdata {
  margin-top: 48px;
}

.servingdata label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 8px;
}

.serving .w-half {
  width: 80% !important;
}

.servinginputs {
  margin-top: 32px;
}

.servinginputs h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 11px;
}

.servinginputs .fullinputs {
  margin-top: 12px;
}

.servinginputs .fullinputs label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  /* color: #6a6e79; */
  color: #fff;
  margin-right: 32px;
  width: 50%;
  /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
  word-break: break-all;
}

.servinginputs .fullinputs input {
  background: #161d2f;
  border-radius: 4px;
  padding: 8px;
  /* color: #6a6e79; */
  color: #fff;
  width: 70%;
  height: 34px;
}

.last {
  margin-top: 32px;
}

.whalf {
  margin-top: 24px;
}

.fullinputs {
  gap: 12px;
}

.register_inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.register-form .w-half {
  width: 100% !important;
}

.darkblue {
  background: #212b46;
}

.register-form .p-dropdown {
  background: #232025 !important;
}

.register-form .p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d !important;
  text-align: left;
  padding: 12px 0px 12px 50px;
}

.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled),
.register-form .p-inputtext,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #212b46 !important;
  padding: 6px !important;
  font-size: 12px !important;
  color: #fff !important;
}

.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: #212b46 !important;
  padding: 6px !important;
  font-size: 12px;
  color: #fff !important;
}

.mlangleslogo {
  width: 200px;
  height: auto;
  cursor: pointer;
}

.mlangles360logo {
  width: 300px;
  height: auto;
  cursor: pointer;
}

/* .logohover:hover .mllogo {
    display: none;
}

.logohover:hover .newlogo {
    display: block;
    margin: 0px;
    transition: all .5s ease-in-out;
} */

.logohover .newlogo {
  /* display: none; */
  width: 55%;
  padding-left: 6px;
  transition: all 0.5s ease;
  cursor: pointer;
}

.newlogos {
  width: 180px;
  cursor: pointer;
}

.phonenumber {
  width: 30%;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  display: none !important;
}

.noprojects {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
}

.noprojects h4 {
  color: #6a6e79;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.noprojects button,
.dialogAllprojects button {
  background: #161d2f;
  border-radius: 4px;
  color: #ffffff;
  border: 0px;
  padding: 8px 16px;
  border: none;
  outline: none;
}

.p-dialog-header-icons button span::before {
  background: #6a6e79;
}

.dialogAllprojects input {
  width: 80%;
  padding: 6px 12px;
  border-radius: 6px;
  background: #161d2f;
  color: #6a6e79;
}

.dialogAllprojects textarea {
  border-radius: 6px;
  background: #161d2f;
  color: #6a6e79;
  width: 80%;
  margin-top: 22px;
  padding: 6px 12px;
  outline: none;
  border: none;
}

.dialogAllprojects textarea:focus {
  outline: none;
  border: none;
}

.dialogAllprojects button {
  margin-top: 22px;
}

textarea {
  resize: none !important;
}

.capitailze {
  text-transform: capitalize;
}

.allprojectsbox .dropdown-menu.show {
  top: -34px !important;
  left: -130px !important;
}

.green {
  color: #b0d136 !important;
}

.red {
  color: #ef1b2e !important;
}

.blue {
  color: #0e8ebe !important;
}

.topspace {
  margin-top: 36px;
}

.w400 {
  width: 400px;
}

.buttonfullwidth {
  width: 33%;
  padding: 6px 28px !important;
  height: 40px;
}

.allpipedropbox .css-q8hpuo-MuiFormControl-root {
  background: #212b46 !important;
  border-bottom: 1px solid #161d2f !important;
  border-radius: 6px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #6a6e79 !important;
  margin-top: -8px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: #6a6e79 !important;
}

.MuiSelect-select {
  color: #6a6e79 !important;
  padding: 8px 16px;
}

.MuiInputBase-root,
.MuiInputBase-root:hover,
.MuiSelect-select:hover,
.MuiSelect-select:focus,
.MuiSelect-select:focus-visible,
.MuiSelect-select:active,
.MuiSelect-select:visited,
.MuiSelect-select:focus-within,
.MuiSelect-select:target,
.MuiOutlinedInput-notchedOutline {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.MuiInputBase-root.modelselect:hover {
  opacity: 0.5 !important;
  background: #161d2f !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  background: #161d2f !important;
  color: #6a6e79 !important;
  padding: 12px 18px !important;
  border-bottom: 1px solid #2b2d32 !important;
}

.css-q8hpuo-MuiFormControl-root:focus-visible,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:focus-visible {
  border: none !important;
  outline: none !important;
}

.each-check .css-fvipm8:focus-visible {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.buildbtn {
  text-align: center;
  width: 20%;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}

.middlepart1 {
  padding: 60px 0px 0px 64px;
  /* margin-top: -6px; */
  /* margin-left: -5px; */
}

.run {
  padding: 25px 25px;
}

.padTop80px {
  padding-top: 80px;
}

.run-checkbox {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  width: 100%;
  /* margin-top: 20px; */
}

.each-check {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-bottom: 36px;
}

.marTop48 {
  margin-top: 48px;
}

.pos-rel {
  position: relative;
}

.abs-dropdown {
  width: 150px !important;
  margin-left: -80px !important;
  background: #212b46 !important;
  margin-top: -40px !important;
}

.run-checkbox label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #e8e8e8;
  margin-bottom: 6px;
}

.run-checkbox input {
  background: #161d2f;
  color: #b1b1b1;
  border: 1px solid #161d2f;
  border-radius: 7px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 9px 24px;
}

.each-check1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-bottom: 20px; */
}

.labelstyle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #e8e8e8;
  margin-bottom: 8px;
}

.css-qiwgdb,
.css-qiwgdb.MuiSelect-select {
  padding: 0px !important;
}

#demo-simple-select {
  padding: 10px 24px !important;
  color: #b1b1b1 !important;
  font-size: 14px !important;
}

.each-check
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px !important;
}

.run .algo {
  margin-top: 40px;
  padding: 26px 0px;
  background: rgba(9, 16, 31, 0.29);
  border-radius: 14px;
}

.algo-input {
  padding: 0px 12px;
  display: flex;
  flex-wrap: wrap;
}

.each-algoinp {
  margin-bottom: 24px;
  margin-right: 18px;
  padding: 12px;
  display: flex;
  align-items: center;
  width: 300px;
  cursor: pointer;
}

.each-algoinp label {
  margin-left: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #b1b1b1;
}

.hyper {
  margin-top: 48px;
}

.run h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  text-transform: uppercase;
  margin-bottom: 36px;
}

table {
  min-width: 100%;
}

.hyper-table th,
.hyper-table td {
  text-align: center;
}

.hyper-table thead tr {
  background: #161d2f;
}

.p-component {
  font-family: "Nunito", sans-serif !important;
}

.pading-table-td {
  padding: 18px;
}

.pading-table-td label {
  margin-right: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #6a6e79;
}

.pading-table-td input {
  padding: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #6a6e79;
  background: #161d2f;
  border: 1px solid #1a1e22;
  border-radius: 4px;
}

.MuiPaper-elevation1,
.css-177ic5c {
  background: transparent !important;
}

.width10 {
  width: 10%;
}

.dropdown-serving {
  border-radius: 3px;
}

.width20 {
  width: 20% !important;
}

.width30 {
  width: 30% !important;
}

.width40 {
  width: 40% !important;
}

.width50 {
  width: 68% !important;
}

.width60 {
  width: 60% !important;
}

.width70 {
  width: 70% !important;
}

.width80 {
  width: 80% !important;
}

.width90 {
  width: 90% !important;
}

.width95 {
  width: 95% !important;
}

.w100 {
  width: 100% !important;
}

.hyper-th {
  font-weight: 500;
  font-size: 16px;
}

.hyper-td {
  font-weight: 300;
  font-size: 16px;
}

.hyper-th,
.hyper-td {
  font-style: normal;
  line-height: 27px;
  color: #e8e8e8;
  padding: 6px 0px;
  margin: 0px;
  padding-left: 26px;
}

.leftnumber {
  width: 80%;
  margin-left: 10%;
}

.head-hyper {
  display: flex;
  flex-wrap: wrap;
}

.equalwidth {
  width: 30%;
  margin: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #6a6e79;
}

.column-dir {
  flex-direction: column;
}

.table-heading-hyper {
  background: #161d2f;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 99;
}

.mar12 {
  /* margin-top: 18px; */
  padding: 4px;
  border-bottom: 1px solid #353538;
}

.head-hyper label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #b1b1b1;
  display: flex;
  align-items: center;
  /* width: 50%; */
}

.head-hyper input {
  padding: 8px 16px;
  border: none;
  outline: none;
  background: #161d2f;
  color: #b1bbb1;
  width: 50%;
  /* width: 218px; */
  border-radius: 6px;
}

.hyper-table {
  margin-top: 30px;
  height: 40vh;
  overflow-y: scroll;
  position: relative;
}

.crerun-btn {
  margin-top: 40px;
  border: none;
  outline: none;
  padding: 8px 20px;
  background: #212b46;
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}

.css-bpeome-MuiSvgIcon-root-MuiSelect-icon,
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
.css-1636szt,
.MuiSelect-iconOpen {
  color: #6a6e79 !important;
  margin-right: 8px;
}

label.MuiInputLabel-formControl {
  color: #b1b1b1 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  font-family: "Nunito", sans-serif !important;
}

.css-177ic5c {
  background: transparent;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border-radius: 0px !important;
}

.black {
  background: #1a1e22;
}

.p-sidebar-right .p-sidebar {
  width: 50rem !important;
  margin-top: 120px !important;
  border-top: 1px solid #222839;
  border-left: 1px solid #222839;
}

.p-sidebar-header {
  display: none !important;
}

.p-sidebar-content {
  background: #161d2f !important;
  color: #6a6e79;
}

.closeicon {
  cursor: pointer;
}

.he56 {
  height: 56px;
}

.sideright-head {
  padding: 8px 12px;
  border-bottom: 1px solid #313747;
  width: 108%;
  margin-left: -50px;
  padding-left: 50px;
}

.sideright-head h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #b0d136;
  margin: 0px;
  text-transform: uppercase;
}

.right-content {
  margin-top: 40px;
}

.right-content .proj {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #fefefe;
}

.clearfilter {
  margin-right: 26px;
}

.commonbtn img {
  margin-right: 8px;
}

.fieldError {
  font-size: 12px;
  color: red;
  margin-left: 4px;
}

.right-content .proj .name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #6a6e79;
  width: 300px;
  word-break: break-all;
}

.proj table {
  width: 80%;
}

.right-content .des {
  margin-top: 36px;
}

.right-content h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #b0d136;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.right-content .des .box {
  border: 1px solid #222839;
  border-radius: 5px;
  padding: 18px;
  width: 100%;
  background-color: #161d2f;
}

.assigned input {
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #2b2d32;
  background: #0d1322;
  padding: 6.5px 16px;
  color: #6a6e79;
  width: 100%;
  margin-left: 10px;
}

.assigned button {
  border: none;
  outline: none;
  padding: 4px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #6a6e79;
  background: #161d2f;
}

.pending {
  margin-top: 34px;
}

.bothpipebtn {
  margin-top: 30px;
  margin-bottom: 8px;
}

.bothpipebtn .btnpipeline {
  margin-left: 20px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 3px;
}

.bothpipebtn button {
  border: none;
  outline: none;
  background: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #ffffff;
}

.git {
  position: absolute;
  top: -70%;
  right: 19%;
}

.bothpipebtn .deleting,
.bothpipebtn .deleting button {
  background: #212b46 !important;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.w40px {
  width: 40px;
}

button {
  border-radius: 5px;
}

.navigate {
  margin-top: 32px;
}

.navigate input {
  width: 60px;
  text-align: right;
}

.navigate .left {
  margin-right: 18px;
}

.navigate .both {
  border-radius: 50%;
  border: 1px solid #ffffff50;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigate .right {
  margin-left: 18px;
}

.navigate .left svg {
  margin-left: 8px;
}

.input {
  font-family: "Nunito", sans-serif !important;
}

.runidh4 {
  color: #959595 !important;
}

.runpad {
  padding: 30px;
  display: flex;
}

.runpad .eachrun {
  margin-right: 40px;
}

.runpad span {
  padding: 6px;
  background: #212b46;
  margin-left: -8px;
}

.runimg {
  border-radius: 50%;
  padding: 12px;
  background: #212b46;
}

.runidtd {
  cursor: pointer;
}

.navbars-side {
  margin-top: 42px;
  margin-right: -20px;
}

.inputrightspace input {
  margin-right: 16px !important;
}

.run-navbar {
  display: flex;
  padding: 8px 24px;
  background: #212b46;
}

.eachnav {
  margin-right: 65px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.eachnav span {
  margin-left: 12px;
}

.sidemarleft {
  margin-left: -1.25rem !important;
}

/* .fixedsize table {
    width: 100% !important;
} */

.pi-spin {
  color: #ffffff;
}

.runbar-content {
  background: #070d19;
  height: 70vh;
  padding: 32px 25px 20px 25px;
  overflow: scroll;
}

.runbar-content table {
  width: 80%;
}

.runbar-content th {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
}

.fullfolder td {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #e8e8e8;
  border-bottom: 1px solid #161d2f;
  background: #161d2f;
}

.subfile tr td {
  color: #5a5a5a;
}

.subfile tr td span {
  padding-right: 80px;
}

.scroll {
  overflow: scroll;
}

.marRight64 {
  margin-right: 64px;
}

.each-pipehead {
  background: #0f131b;
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 56px;
}

.each-pipehead h6 {
  font-size: 16px;
}

/* .allpipelines {
    margin-top: 48px;
} */

.each-pipehead h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding: 21px;
  cursor: pointer;
  margin: 0px;
}

.runbar-content [data-testid="FolderIcon"] {
  color: #aaaaaa !important;
}

.runbar-content [data-testid="DownloadIcon"] {
  color: #5a5a5a !important;
}

.eachdownload-span {
  color: #e8e8e8;
}

.eachfulldownload [data-testid="FolderIcon"] {
  margin-right: 16px;
}

.eachfulldownload span {
  margin-right: 32px;
  cursor: pointer;
}

.eachfulldownload {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.subfile {
  width: 100% !important;
}

.flex-column {
  flex-direction: column;
}

.cursor {
  cursor: pointer;
}

.viewall {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0px;
  color: #525252;
  /* text-decoration: underline; */
  border-bottom: 1px solid #525252;
  margin-left: 8px;
  cursor: pointer;
  margin: 32px;
  margin-bottom: 22px;
}

.viewall:hover,
.recenthome p:hover {
  color: #0e8ebe;
  border-bottom: 1px solid #0e8ebe;
}

.pipename1:hover {
  color: #0e8ebe;
}

.view-right {
  display: flex;
  justify-content: flex-end;
}

button {
  border-radius: 3px;
}

.scroll-logs {
  height: 45vh;
  overflow: scroll;
  margin-left: 18px;
}

.scroll-logs p {
  font-size: 14px;
}

.timebox {
  font-size: 12px !important;
  margin: 0px !important;
}

.pipes1 .p-tabview .p-tabview-panels {
  padding: 0px !important;
}

.pi-spinner {
  margin-top: 32px;
}

.padd-24 {
  padding: 24px 24px 0px 24px;
}

.paddboth {
  padding: 40px 25px 0px 25px;
  margin-top: 48px;
}

.mt82px {
  margin-top: 82px;
}

.martop60px {
  margin-top: 60px;
}

.allexperiments
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background: #212b46 !important;
}

.blackbg {
  background: #0d1322;
}

.inside-ser {
  padding: 48px;
  padding-top: 0px;
}

.marright100 {
  margin-right: 100px;
}

.servinginputs .eachinput {
  width: 20%;
  /* display: flex; */
  margin: 18px;
  /* justify-content: space-between; */
}

.predict {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  background: #161d2f;
  border-radius: 3px;
  padding: 8px 28px;
  border: none;
  outline: none;
}

.img100 {
  width: 500px;
  height: 500px;
}

.metrics th {
  background: #212b46 !important;
}

.metrics td {
  background: #161d2f !important;
  border-right: 1px solid #070d19;
}

.fa-filter {
  margin-left: 12px;
}

.posrel {
  position: relative;
}

.posabs {
  position: absolute;
  top: 100%;
  right: 0%;
}

.servBtn {
  margin-right: 60px;
}

.search-content {
  padding: 12px !important;
  background: #212b46;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
}

.charLeft {
  float: left;
}

.search-content .textinput {
  padding: 6px;
}

.search-content input {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: #161d2f;
  color: #787878;
  border-radius: 4px;
  width: 100%;
}

.search-content .allcheck {
  margin-top: 18px;
  margin-right: 12px;
  flex-direction: column;
}

.search-content .apply {
  background: #161d2f;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 8px;
  margin-top: 12px;
  cursor: pointer;
}

.allcheck .eachboxcheck {
  margin-bottom: 8px;
  display: flex;
}

.allcheck .eachboxcheck label {
  cursor: pointer;
  /* width: 90%; */
  width: 120px;
  word-break: break-all;
}

.padTop40 {
  padding-top: 40px;
}

#toggleDropdown {
  height: 180px;
  overflow: scroll;
}

.assigned1 .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background: #212b46;
  color: #6a6e79;
  border: none;
}

.assigned1 .add {
  border-radius: 0px 3px 3px 0px;
  border: 1px solid #2b2d32;
  background: #0d1322;
  color: #b1b1b1;
}

.assigned1 .add:hover {
  opacity: 0.5;
}

.chips {
  margin-top: 36px;
}

.chips .p-chips-multiple-container,
.chips .p-chips-multiple-container:hover {
  background: #161d2f !important;
  border: none !important;
  outline: none !important;
  padding: 0px !important;
}

.chips .p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  box-shadow: none !important;
}

.chips .p-chips .p-chips-multiple-container .p-chips-token {
  color: #6a6e79 !important;
  background: #161d2f !important;
  border-radius: 12px !important;
  border: 1px solid #222839;
}

.chips .end {
  display: flex;
  justify-content: flex-start;
  margin-top: 34px;
}

.chip-btn {
  border: none;
  outline: none;
  padding: 4px 8px;
  border-radius: 4px;
  background: #212b46;
  color: #6a6e79;
}

.cursorhover:hover {
  color: #0e8ebe;
}

.posabs1 {
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
}

.posabs1 p {
  margin: 0px;
  background: #212b46;
  border: 1px solid rgba(106, 110, 121, 0.14);
  border-radius: 4px 0px 0px 4px;
  padding: 8px 16px;
  color: #6a6e79;
  cursor: pointer;
}

.failed {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ef1b2e !important;
}

.running {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #0e8ebe !important;
}

.flexing {
  display: flex;
  flex-wrap: wrap;
}

.w33 {
  width: 30%;
}

.eachimges {
  margin: 20px;
}

.logout button:hover {
  opacity: 0.75;
}

.goto-serv {
  padding: 12px 16px;
  border-radius: 6px;
  background: #212b46;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  margin-right: 60px;
}

.gap50 {
  gap: 50px;
}

.pad-left40 {
  padding-left: 40px !important;
}

.notifiIcon {
  margin-right: 15px;
}

.filter {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.ml-8 {
  margin-left: -8px;
}

.plus {
  width: 12px;
  height: 12px;
  margin-right: 6px;
}

.deleteIcon {
  width: 12px;
  height: 12px;
  margin-bottom: 2px;
}

.fixedTable {
  height: 67vh;
  overflow: scroll;
}

.fixedTable table thead {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
}

.martop20 {
  margin-top: 20px;
}

.arrowrightlinco {
  position: absolute;
  width: 50%;
  height: 4px;
  border-radius: 2px;
  top: calc(50% - 2px);
  left: 100%;
  background: #5a5a5a;
}

.arrowiconco {
  position: absolute;
  top: 3px;
  left: 50%;
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowIcon {
  font-size: 30px;
  color: #5a5a5a;
}

.odd-arrow-bottom {
  position: absolute;
  bottom: 100%;
  left: calc(50% - 3px);
  border-radius: 2px;
  width: 4px;
  height: calc(40vh - 226%);
  background: #5a5a5a;
}

.odd-arrowIcon-bottomup {
  transform: rotate(90deg) translate(0px, -2px);
  position: absolute;
  bottom: 50%;
  height: 0px;
  width: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.even-arrow-bottom {
  position: absolute;
  top: 100%;
  left: calc(50% - 4px);
  width: 4px;
  border-radius: 2px;
  height: calc(40vh - 226%);
  background: #5a5a5a;
}

.load {
  width: 152px;
}

.even-arrowIcon-bottomup {
  transform: rotate(-90deg) translate(0px, 2px);
  position: absolute;
  bottom: 50%;
  height: 0px;
  width: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.pipes1 .p-tabview-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding-left: 22px;
}

.logging .p-tabview-title {
  padding-left: 0px !important;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.page-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 18px 22px;
  background: #0f131b;
  color: #6a6e79;
  cursor: pointer;
  margin: 0px;
}

.active {
  color: #b0d136;
}

.allcontent {
  padding-top: 82px;
}

.profile1 {
  padding: 25px 65px 25px 25px;
  border-right: 1px solid #2b2d32;
  height: 80vh;
  width: 20%;
}

.name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #b1b1b1;
  margin: 0px;
  margin-top: 15px;
}

.role {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #b1b1b1;
  margin: 0px;
  margin-top: 4px;
}

.inputprofile {
  padding: 25px 48px;
}

.lock {
  width: 5%;
}

.inputprofile label {
  margin-bottom: 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #b1b1b1;
}

.inputcommontextforgot {
  background: #212b46;
  border-radius: 8px;
  padding: 11px 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #6a6e79;
  width: 100%;
}

.inputprofile input {
  background: #161d2f;
  border-radius: 8px;
  padding: 11px 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #6a6e79;
  width: 100%;
}

.eachboxinputprofile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 38px;
  margin-bottom: 32px;
}

.inputprofile .change {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #b0d136;
  padding-bottom: 2px;
  border-bottom: 1px solid #b0d136;
  display: inline;
  cursor: pointer;
}

.commonbtn {
  background: #161d2f;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 4.5px 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #2b2d32;
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.newprojectbtn {
  border-radius: 5px;
  border: 1px solid #2b2d32;
  background: #0d1322;

  outline: none;
  padding: 4.5px 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #2b2d32;
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.expcommonbtn {
  background: #161d2f;
  border-radius: 6px;
  border: none;
  outline: none;
  padding: 6px 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #2b2d32;
  margin-left: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 33.5px;
}

/* .each-check1 label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
} */

.gotoserv {
  margin-right: 62px;
}

.inputprofile .savebtn {
  margin-top: 48px;
  /* width: 14%; */
  margin-left: 0px;
}

.editbtn {
  background: #161d2f;
  border: 1px solid #2b2d32;
  border-radius: 3px;
  padding: 6px 18px;
  margin-right: 22px;
  cursor: pointer;
  width: 90px;
  margin-right: 36px;
}

.editbtn span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #ffffff;
  margin-left: 8px;
}

.pencil {
  width: 10px;
  height: 10px;
}

.headicon {
  margin-right: 22px;
}

.rightside {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.modalshowforgot {
  height: 400px;
  width: 600px;
  background: #161d2f;
  border: 2px solid #161d2f;
  border-radius: 5px;
  padding: 30px 40px;
}

.modalshowforgot:focus-visible {
  border: none;
  outline: none;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.head-forgot {
  display: flex;
  justify-content: center;
  align-items: center;
}

.head-forgot p {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: #6a6e79;
  margin: 0px;
  margin-left: 18px;
}

.closex {
  float: right;
  cursor: pointer;
}

.modal-con {
  margin-top: 22px;
}

.marginNone {
  margin: 0px !important;
}

.labelforgot {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #b1b1b1;
  margin-bottom: 14px;
}

.each-for-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: 100%;
}

.gap20 {
  gap: 20px;
}

.gap30 {
  gap: 30px;
}

.backmodalbtn {
  background: #212b46;
}

.modal-con-for {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.colwise {
  flex-direction: column;
}

.successMess {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: #6a6e79;
  margin-bottom: 55px;
}

.chips .p-chips:focus-visible,
.chips .p-chips:hover {
  outline: none !important;
}

.chips .p-inputwrapper-focus {
  border: none;
  outline: none;
}

.dropdown-serving {
  background: #161d2f;
  width: 80% !important;
}

input[type="radio"] {
  width: 18px !important;
  height: 18px !important;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  background: #2b2d32;
  margin: 0px 8px 0px 0px;
  color: #2c2333;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50% !important;
  padding: 4px !important;
  cursor: pointer !important;
}

input[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  background: #2b2d32 !important;
  margin: 2px 8px 0px 0px;
  color: #2c2333;
  width: 18px;
  height: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 4px !important;
  cursor: pointer !important;
}

.deletebtn {
  width: 13px;
  height: 13px;
}

input[type="radio"]::before {
  position: absolute;
  top: 4px;
  left: 4px;
  content: "";
  width: 8px;
  height: 8px;
  transition: 120ms transform ease-in-out;
  background: #9dbc35;
  transform: scale(0);
  opacity: 0;
  border-radius: 50%;
}

input[type="checkbox"]::before {
  position: absolute;
  top: 2px;
  left: 2px;
  content: "";
  width: 12px;
  height: 12px;
  transition: 120ms transform ease-in-out;
  background: #9dbc35;
  transform: scale(0);
  opacity: 0;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="radio"]:checked::before {
  transform: scale(1);
  opacity: 1;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
  opacity: 1;
}

.firstCheckbox {
  width: 25px;
}

.profile-checkbox {
  height: 44px;
}

.profile-checkbox
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  height: 100%;
}

.radioChecking {
  width: 18px !important;
  height: 18px !important;
  position: relative !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  background: #2b2d32 !important;
  margin: 0px 8px 0px 0px !important;
  color: #2c2333 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 50% !important;
  padding: 4px !important;
  cursor: pointer !important;
}

.radioChecking::before {
  position: absolute !important;
  top: 3px !important;
  left: 3px !important;
  content: "" !important;
  width: 10px !important;
  height: 10px !important;
  transition: 120ms transform ease-in-out !important;
  background: #9dbc35 !important;
  transform: scale(0) !important;
  opacity: 0 !important;
  border-radius: 50% !important;
  clip-path: none !important;
}

.radioChecking:checked::before {
  transform: scale(1) !important;
  opacity: 1 !important;
}

.commonbackbtn:hover,
.commonbtn:hover,
.eachbutton:hover,
.expcommonbtn:hover,
.createbtn:hover,
.predict:hover,
.newprojectbtn:hover {
  opacity: 0.5;
}

.padleft46 {
  padding-left: 46px;
}

.width140px {
  width: 140px;
}

.capitalize {
  text-transform: uppercase;
}

.result {
  margin: 0px;
  margin-left: 18px;
  color: #fff;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background: #161d2f !important;
}

.textoverflow {
  /* display: -webkit-box; */
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.column-dir {
  display: flex;
  flex-direction: column;
}

.largeData {
  display: none;
}

.hover:hover .largeData {
  display: block;
  cursor: pointer;
}

.finalres {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  background: #212b46;
  border-radius: 3px;
  padding: 8px 28px;
  margin-left: 12px;
}

.removeIcon {
  cursor: pointer;
  margin-left: 12px;
}

.pending table thead {
  background: #212b46;
}

.pending table tbody {
  background: #070d19;
}

.pending th,
.pending td {
  width: 50%;
}

.pending table {
  margin-bottom: 64px;
  width: 100%;
}

.remove {
  background: #212b46;
  outline: none;
  border: none;
  padding: 4px 12px;
  color: #ffffff;
  border-radius: 4px;
  margin-bottom: 12px;
  cursor: pointer;
}

.pad12 {
  padding: 12px;
}

iframe {
  width: calc(100vw - 65px);
  height: calc(100vh - 60px);
}

.each-check .css-fvipm8 {
  border: 1px solid #161d2f !important;
  border-radius: 7px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #b1b1b1 !important;
  background: #161d2f !important;
  /* padding: 12px 24px !important; */
}

.anchor {
  color: #ffffff;
}

.dropContent {
  margin-left: 12px;
}

.userphoto {
  width: 80px;
  height: 80px;
  background-color: #161d2f;
  /* Set your desired background color */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  /* Set your desired font size */
  color: #b1b1b1;
  /* Set your desired text color */
  font-weight: bold;
  text-transform: uppercase;
}

.fileselect {
  width: 80px;
  height: 80px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.relative {
  position: relative;
}

.martop100 {
  margin-top: -100px;
}

.activepdata {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
}

.accept {
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #b1b1b1;
  padding: 4px 22px;
  margin-right: 12px;
}

.decline {
  border: 1px solid #2b2d32;
  padding: 4px 22px;
  color: #686868;
  background: #0d1322;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 1rem !important;
}

.marSidebar {
  margin-top: 6px;
  padding-left: 12px;
}

.marSidebar table th {
  border: none;
}

.MuiMenu-list .MuiMenuItem-gutters.capitailze {
}

.MuiMenu-list .MuiMenuItem-gutters {
  background: #161d2f !important;
  color: #b1b1b1 !important;
  padding: 12px 18px !important;
  border-bottom: 1px solid #2b2d32 !important;
  font-size: 14px !important;
}

.css-r8u8y9 {
  padding: 0px !important;
}

.MuiInputBase-formControl {
  /* color: #6a6e79;
    background: #212B46; */
  background: #161d2f;
  color: #b1b1b1;
}

label.MuiInputLabel-shrink {
  display: none !important;
}

.acceptProject {
  background: #212b46;
}

.logout {
  padding: 30px 60px;
  border-radius: 12px;
}

.logout-dialogbox {
  width: 30vw !important;
}

.logout-dialogbox .p-dialog-header {
  display: none;
}

.logout-dialogbox .p-dialog-content {
  padding: 20px 30px !important;
}

.logout-dialogbox .p-dialog-content {
  border-radius: 12px !important;
}

.logout h4 {
  font-size: 28px;
  line-height: 38px;
}

.logout h6 {
  font-size: 16px;
  line-height: 22px;
}

.logout h4,
.logout h6 {
  color: #b1b1b1;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 38px;
  font-size: 16px;
}

.logout button {
  background: #161d2f;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  border: none;
  outline: none;
  padding: 8px 32px;
  margin-right: 18px;
}

.error-message {
  color: red;
  font-size: 16px;
  margin-top: 32px;
  text-align: center;
}

.settings-icon {
  margin-right: 17px;
}

.setting {
  padding: 40px 0px 0px 22px;
}

.settings-input {
  width: 46% !important;
}

.setting h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 24px;
}

.setting h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #b1b1b1;
  margin-bottom: 12px;
}

.setting input {
  background: #161d2f;
  border-radius: 3px;
  color: #ffffff;
  padding: 14px 20px;
  margin-bottom: 48px;
  width: 100%;
}

.setting .padRight {
  padding-right: 22px;
}

.eachvalue {
  width: 20%;
}

.posrel {
  position: relative;
}

.searchIcon {
  position: absolute;
  top: 34%;
  right: 5%;
}

.spliting,
.spliting1 {
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.spliting1 {
  position: absolute;
  top: 12%;
  left: 80px;
}

.searchIconAss {
  position: relative;
  top: 3%;
  right: 30%;
}

.allProjectspage {
  min-height: 100vh;
}

.formAssign {
  display: flex;
  /* justify-content: flex-end; */
  width: 100%;
  margin-bottom: 10px;
}

.infoIcon {
  background: #ffffff90;
  color: #000000;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 900;
  width: 12px;
  height: 12px;
  margin-left: 4px;
  text-align: center;
  line-height: 12px;
  cursor: pointer;
}

.showInfo {
  width: 100%;
  position: absolute;
  bottom: 100%;
  right: 0%;
  font-size: 10px;
  color: #ffffff;
  display: none;
}

.hoverInfo {
  position: relative;
  width: 50%;
  padding-right: 12px;
}

.hoverInfo:hover .showInfo {
  display: block;
}

.infoLabel {
  display: flex;
}

.fixedsubttab {
  position: fixed;
  width: 100%;
  z-index: 99;
}

.padTop60 {
  padding-top: 60px;
}

.width160px {
  width: 160px;
}

.visNone {
  visibility: hidden;
}

.visBlock {
  visibility: initial;
}

.marginbtm {
  margin-bottom: 10px;
}

.logbutton {
  color: #fff;
  font-size: 12px;
  border-radius: 6px 3px 4px 0px;
  background: #6a6e79;
  padding: 6px 10px;
  position: absolute;
  bottom: 100%;
  /* left: 100%; */
  width: 78px;
  z-index: 1000;
  display: none;
}

.logHover:hover .logbutton {
  display: block;
}

.inside-serving1 {
  padding: 0px 40px 40px 28px;
}

.inside-serving1 h6 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #b0d136;
  text-transform: uppercase;
}

.selectingServing {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 350px);
}

.selectSeveBox {
  padding: 30px 60px;
  background: #161d2f;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.selectSeveBox:hover p {
  /* opacity: 0.5; */
  color: #9dbc35;
}

.selectSeveBox p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
  margin: 0px;
  text-align: center;
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}

.servingcheckbox {
  margin-right: 18px !important;
}

.padRig38 {
  padding-right: 38px;
}

.serve-inside,
.inside-serving {
  padding: 55px 28px;
}

.newScehduleServ {
  padding: 55px 28px 0px 28px;
}

.name-ser {
  margin-top: 24px;
}

.serpro-top {
  background: #0d1322;
  padding: 52px;
  display: flex;
  align-items: center;
  gap: 18px;
  flex-wrap: wrap;
  margin-bottom: 52px;
  padding-top: 32px;
}

.serpro-to {
  background: #0d1322;
  padding-top: 32px;
  margin-top: 32px;
}

.mar0 {
  margin: 0px !important;
}

.serpro-bottom {
  padding: 52px;
  background: #0d1322;
}

.serpro-bottom1 {
  padding: 52px;
  background: #070d19;
}

.serpro-eachinput {
  width: 24%;
}

.width18px {
  width: 18px;
}

.connects3element {
  padding: 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #212b46;
  height: 43px;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
}

.connects3element p {
  color: #b1b1b1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}

.fileuploading,
.fileupload {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  /* z-index: -999; */
}

.fileup {
  position: relative;
}

.fileuploading input {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.serpro-bottom h4,
.serpro-bottom1 h4 {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.serveprobottombox {
  display: flex;
  /* align-items: center; */
  gap: 38px;
  margin: 28px 0px;
  flex-wrap: wrap;
}

.connects3element:hover,
.connects3element :hover,
.fileuploading input:hover,
.serpro-eachinput:hover {
  background: #2d3b60;
}

.serveprobottombox input,
.serveprobottombox .inputlike {
  padding: 12px 24px;
  border-radius: 4px;
  background: #212b46;

  color: #b1b1b1;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 20%;
}

.serveprobottombox input:hover,
.darkBackgro:hover {
  background: #2d3b60;
}

.w20per {
  width: 100% !important;
}

.blueblack {
  background: #161d2f !important;
}

.blueblack:hover {
  background: #212b46 !important;
}

.nextbtn {
  padding: 8px 22px;
  background: #212b46;
  color: #ffffff;
  border-radius: 4px;
  margin-top: 48px;
}

.nextbtn:hover {
  background: #161d2f;
}

.darkBackgro {
  background: #161d2f !important;
}

.blueinput {
  background: #212b46 !important;
}

.scheduleEachBox {
  background: #0d1322;
  padding: 30px 24px;
  width: 18%;
}

.scheduleCheckbox {
  display: flex;
  flex-direction: column;
}

.scheduleCheckbox .d-flex {
  margin-bottom: 6px;
}

.scheduleEachBox h3 {
  color: #b0d136;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-bottom: 22px;
}

.schedulelabel {
  display: flex;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.scheduleselect {
  padding: 4px;
  background: #161d2f;
  color: #ffffff;
  width: 60px;
  border-radius: 4px;
}

.chatbotimg {
  position: fixed;
  left: 10%;
  bottom: 10%;
  cursor: pointer;
  width: 100px;
  height: auto;
  z-index: 9;
}

.flexCenter {
  display: flex;
  /* align-items: center;
    justify-content: center; */
  width: 100%;
}

.flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.center-chat {
  width: 60%;
  position: relative;
  /* margin-left: 8%; */
}

.chatbot .nav-link {
  padding: 12px 80px !important;
  color: #ffffff !important;
  background: #161d2f !important;
  border-radius: 0px !important;
}

.chatbot {
  margin-top: 4px;
}

.juscenter {
  display: flex;
  justify-content: center;
  width: 100%;
}

.f-col {
  display: flex;
  flex-direction: column;
}

.chatbot .nav-link.active {
  background: #212b46 !important;
}

.chatinput {
  padding: 8px 12px !important;
  background: #212b46 !important;
  color: #ffffff !important;
  /* width: 48%; */
  border-radius: 6px !important;
  position: fixed !important;
  bottom: 5% !important;
  width: 100% !important;
  position: absolute !important;
  padding-right: 32px !important;

  border: none !important;
  outline: none !important;
}

.chatinput:hover {
  background: #161d2f;
}

.chatinput:focus,
.p-inputtext:enabled:focus,
.chatinput:hover {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.formchat {
  /* width: calc(100vw -75px); */

  width: 100%;
  position: absolute;
}

.mainchat {
  display: flex;
  flex-direction: column;
  gap: 36px;
  height: 78vh;
  overflow-y: scroll;
  padding-right: 8px;
}

.eachchat {
  display: flex;
  gap: 12px;
}

.chatbot .tab-pane.active {
  color: #fff !important;
}

.eachchat span {
  padding: 6px;
  background: #bfbfbf;
  color: #000000;
  border-radius: 50%;
  align-self: flex-start;
}

.flexStart {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.userInput {
  background: #b1b1b1;
  color: #000000;
  padding: 8px;
  border-radius: 6px;
  word-break: break-all;
  margin-bottom: 12px;
}

.roboOutput {
  background: #212b46;
  padding: 12px;
  border-radius: 6px;
  word-break: break-all;
  color: #ffffff90;
}

.downIconplugin {
  width: 16px;
}

.roboOutput p {
  margin: 0px;
}

.roboout-logo {
  height: 48px;
}

.scheduleBtn {
  padding: 8px 24px;
  background: #212b46;
  color: #ffffff;
  /* margin-top: 4%; */
  margin-left: 1.5%;
  border-radius: 3px;
}

.capitalTitle {
  text-transform: uppercase;
}

.batch-result {
  position: relative;
  height: 80vh;
  overflow: scroll;
}

.batch-result table tbody .sticked {
  background: #191f2e;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
}

.headback {
  background: #191f2e;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;

  right: 0px;
  top: auto;
  width: 150px;
  line-height: 34px;
  /* height: 80vh; */
  /* overflow: scroll; */
}

.bodyback {
  background: #070d19;
  border-bottom: 1px solid rgba(82, 82, 82, 0.42);
}

.fixedLastOne {
  position: absolute;
  right: 0px;
  top: auto;
  width: 150px;
  line-height: 34px;
  height: 80vh;
  overflow: scroll;
}

.chatstart {
  display: flex;
  justify-content: flex-start;
}

.chatend {
  display: flex;
  justify-content: flex-end;
}

.posrel-chat {
  position: relative;
  width: 100%;
  height: 48px;
}

.formSubBtn {
  position: absolute;
  right: 2%;
  bottom: 22%;
  cursor: pointer;
}

/* .serving h2 {
    padding: 18px 18px 18px 28px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #B0D136;
    margin: 0px;
    width: 100%;
    background: #0F131B;
    position: fixed;
    z-index: 9;
} */

.chatsidebar {
  /* position: absolute;
    left: 75px;
    top: 60px; */
  background: #161d2f;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  transition: 0.5s;
}

.minimized {
  width: 0px;
  padding: 0px;
  /* visibility: hidden; */
  /* display: none; */
  /* overflow: hidden; */

  position: absolute;
  left: 75px;
  top: 60px;
  background: #161d2f;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  overflow: scroll;
  transition: 0.5s;
}

.nosplit {
  display: none;
  width: 0px;
}

.s1 {
  display: flex;
  gap: 10px;
  padding: 12px 0px;
  align-items: center;
  border-bottom: 1px solid #2b2d32;
}

.s12 {
  display: flex;
  gap: 10px;
  padding: 12px 0px;
  flex-direction: column;
}

.s1 h5 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  color: #fff;
}

.s1 input,
.editInput {
  border-radius: 6px;
  background: #212b46;
  color: #fff;
  padding: 4px 8px;
}

.s1 input:hover,
.editInput:hover {
  opacity: 0.5;
}

.editInput {
  background: #161d2f !important;
}

.s1 button {
  padding: 6px 10px;
  border-radius: 3px;
  background: #212b46;
  color: #b1b1b1;
  font-size: 12px;
  font-weight: 400;
  width: 230px;
  /* display: flex;
    align-items: center; */
}

.s1 button img {
  margin-right: 4px;
}

.s1 button:hover {
  opacity: 0.5;
}

.s1 p {
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.gap14 {
  gap: 14px;
}

.eachChatbox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #2b2d32;
  padding: 6px 20px;
}

.eachChatbox:hover {
  background: #212b46;
}

.eachChatbox.active1 {
  background: #212b46;
}

.leftInChat {
  width: 90%;
  align-items: center;
  position: relative;
}

.cleandataurlinput {
  width: 100%;
  color: #6a6e79;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: default;
}

.selectDatabase {
  border-radius: 4px;
  background: #0d1322;
  padding: 27px 54px;
  margin-bottom: 28px;
}

.selectDatabase h4 {
  color: #b1b1b1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}

.w24 {
  width: 24%;
}

.selectDatabase p {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
  margin-bottom: 38px;
}

.selectDatabase button {
  border-radius: 3px;
  background: #212b46;
  color: #fff;
  padding: 8px 28px;
}

.eachChatbox p {
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  cursor: pointer;
  width: 250px;
  /* word-wrap: break-word; */
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.optOne {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  position: absolute;
  right: 1%;
  cursor: pointer;
}

.optOne i {
  cursor: pointer;
}

#mainchatting::-webkit-scrollbar-thumb {
  background: #161d2f !important;
}

.downArrow {
  position: absolute;
  left: 5%;
  top: 33%;
  padding: 4px !important;
  border: 1px solid #6a6e79 !important;
  border-radius: 2px !important;
  cursor: pointer !important;
}

.downarrowContent {
  margin-top: -8% !important;
  margin-left: -40% !important;
  padding: 0px !important;
}

.downarrowContent li .dropdown-item {
  border-bottom: 1px solid #070d19 !important;
  background: #161d2f !important;
  padding: 8px 42px;
}

.downarrowContent li .dropdown-item.active {
  background: #212b46 !important;
}

.downarrowContent li .dropdown-item:hover {
  background: #212b46 !important;
  cursor: pointer;
}

.formEditingData {
  position: absolute;
  left: 7%;
  z-index: 9;
  background: #161d2f;
  width: 95%;
}

/* 
.whittt h5, .whittt p {
    color: #ffffff;
} */

.settingselect .forminput {
}

.settingselect .MuiSelect-outlined {
  padding: 0px 12px;
  height: 16px;
  line-height: 22px;
}

.settingselect .MuiInputBase-input {
  padding: 0px 18px 0px 6px !important;
  font-size: 12px;
}

.settingselect .MuiSelect-icon {
  margin-right: -25%;
}

@-webkit-keyframes autofill {
  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

@keyframes autofill {
  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-delay: 1s;
  /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.dropdown-item:hover {
  background: transparent !important;
}

.heightfix54 {
  height: 54vh;
  overflow-y: scroll;
}

.padtop38 {
  padding-top: 38px;
}

.martoping22 {
  margin-top: -22px;
}

.uploadHead {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 0px 0px 0px 52px;
}

.pad0 {
  padding: 0px;
}

.lastInput {
  border-radius: 3px;
}

.lastInput input {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #b1b1b1;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-radius: 3px;
  background: #212b46;
  padding: 12px 16px;
}

.settingselect .p-dropdown-label,
.settingselect .p-dropdown-trigger {
  padding: 0px !important;
  width: auto !important;
  font-size: 12px;
}

.settingselect .p-dropdown {
  background: #212b46 !important;
  padding: 6px !important;
  font-size: 12px;
}

.settingselect {
  display: flex;
  align-items: center;
}

.settingselect .pi-chevron-down:before {
  font-size: 12px;
  margin-left: 8px;
}

.p-dropdown-items .p-dropdown-item {
  background: #212b46 !important;
  padding: 6px !important;
  font-size: 12px;
}

.settingselect label {
  display: flex;
  gap: 4px;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.padside32 {
  padding: 0px 32px;
}

.fileSuccessInfo {
  position: absolute;
  color: rgba(95, 218, 84, 0.57);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fileSuccessInfo img {
  margin-right: 8px;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
}

.titleFixed {
  position: fixed;
  z-index: 9;
  font-size: 14px;
}

.martop20 {
  margin-top: 20px;
}

.danger {
  color: #ef1b2e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.countr-select {
  height: 20vh;
  overflow-y: scroll;
}

.countr-select .dropdown-item {
  cursor: pointer;
  background: #232025 !important;
  color: #ffffff !important;
}

.countr-select.dropdown-menu.show {
  transform: translate3d(-10px, 49.2px, 0px) !important;
}

.phoneid {
  margin: 0px;
  display: flex !important;
  align-items: center !important;
  position: relative !important;
  border-radius: 8px !important;
  border: 1px solid #5a5a5a !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  border-radius: 8px;
  height: 48px;
  padding: 0px 31px;
  color: #ffffff !important;
  background: #070d19;
  width: 100% !important;
  text-align: center;
}

.phoneid.dropdown-toggle::after {
  position: absolute !important;
  right: 10% !important;
}

.gap12 {
  gap: 12px !important;
}

.p-toast {
  z-index: 999999999;
}

.s1 {
  padding: 12px 20px;
  position: relative;
}

.month {
  padding: 0px 20px;
  color: #fff;
  margin: 0px;
  margin-bottom: 8px;
}

.sameIcon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.onlineServeActive,
.batchServeActive {
  display: none;
}

.online:hover .onlineServe {
  display: none;
}

.online:hover .onlineServeActive {
  display: block;
}

.batch:hover .batchServe {
  display: none;
}

.batch:hover .batchServeActive {
  display: block;
}

.centering {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.w55px {
  width: 55px;
  height: 65px;
}

.fileChange1 {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  cursor: pointer;
  top: 0px;
  left: 0px;
}

.dropFile {
  position: absolute;
  top: 16%;
  left: 33%;
  width: 33%;
}

.filebox {
  padding: 20px 40px;
  border: 1px dashed #474747;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dragp {
  color: #6a6e79;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
  margin-top: 42px;
}

.or {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 17px;
}

.each-project:hover {
  background: #212b46;
}

.defaultBoxName {
  margin: 0px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  color: #ffffff;
  background: transparent;
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.alignItemsCenter {
  align-items: center !important;
}

.s123 {
  padding: 6px 20px !important;
}

.s123:hover {
  background: #212b46;
}

.s123active {
  background: #212b46;
}

.contentImageChat {
  margin-right: 12px;
  width: 18px;
}

.w33 {
  width: 33%;
}

.eachonetab {
  width: 100%;
  border: 0px;
  outline: 0px;
  background: #161d2f;
  margin: 0px;
  padding: 12px 0px;
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-right: 2px solid #000;
}

.eachonetab:hover {
  background: #212b46;
}

.tab-activechat {
  background: #212b46;
}

.pluginright {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 22px;
  cursor: pointer;
  background: #1a1e22;
  margin-bottom: 4px;
}

.pluginright:hover {
  background: #212b46;
}

.pluginrightActive {
  background: #212b46;
}

.rightsidePlugin {
  padding: 8px;
  background: #0d1322;
  min-height: calc(100vh - 80px);
  /* position: absolute; */
  right: 0px;
  top: 60px;
}

.validateFormplugin {
  padding: 30px 0px;
  background: #0d1322;
  margin-top: 38px;
}

.validateFormplugin .edit {
  border: 0px;
  outline: 0px;
  margin: 0px;
  padding: 0px;
}

.eachInplugin {
  display: flex;
  flex-direction: column;
}

.eachInplugin label {
  color: #b1b1b1;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}

.eachInplugin input {
  color: #6a6e79;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 16px 12px;
  border-radius: 3px;
  background: #161d2f;
}

.padding0 {
  padding: 0px;
}

.float-right {
  float: right;
}

.gap24 {
  gap: 24px;
}

.mb24 {
  margin-bottom: 24px;
}

.padinput {
  padding: 0px 60px 0px 45px;
}

.validateFormplugin button {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 16px;
  border-radius: 4px;
}

.validateFormplugin button.validate {
  background: #212b46;
}

.validateFormplugin button.next {
  background: #2b2d32;
  cursor: pointer;
}

.gap8 {
  gap: 8px;
}

.marl22 {
  margin-left: 22px;
  margin-top: 44px;
}

.editbtnn {
  margin-right: 32px;
}

.editbtnn img {
  margin: 0px;
}

.ml22 {
  margin-left: 22px;
  color: #5fda54;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.validateFormplugin .next {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 16px;
  border-radius: 4px;
  background: #2b2d32;
}

.bright {
  background: #212b46 !important;
}

.brightColor:hover {
  background: #212b46 !important;
}

.confHead {
  color: #b0d136;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.gap18 {
  gap: 18px;
}

.score {
  align-items: center;
}

.score h6 {
  color: #6a6e79;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}

.score input {
  border-radius: 4px;
  border: 1px solid #1a1e22;
  background: #161d2f;
  color: #6a6e79;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 12px;
  width: 5%;
}

.score p {
  color: #b0d136;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.i-Icon {
  padding: 3px;
  background: #b0d136;
  border-radius: 50%;
  color: #fff;
}

.fileorfolder {
  display: flex;
  gap: 12px;
}

.imgFluid {
  width: 100% !important;
  height: auto;
}

.fileorfolder .box.active {
  border: 1px dashed #0e8ebe !important;
  /* padding: 22px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 16px;
    cursor: pointer; */
}

.fileorfolder .box {
  border: 1px dashed #6a6e79;
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
}

.fileorfolder .box {
}

.fileorfolder h4.active {
  color: #0e8ebe !important;
}

.fileorfolder h4 {
  margin: 0px;
  color: #6a6e79;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 80%;
  text-align: center;
}

.aboveimg {
  width: 30%;
}

.mainchat1 {
}

.outbox {
  padding: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #0d1322;
  margin-top: 60px;
}

.notshow {
  display: none !important;
}

.filehover:hover .notshow {
  display: flex !important;
}

.filehover:hover .inactive {
  display: none !important;
}

.gap30 {
  gap: 30px !important;
}

.w10 {
  width: 10%;
}

.w20 {
  width: 20%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w80 {
  width: 80%;
}

.w90 {
  width: 90%;
}

.w100 {
  width: 100%;
}

.w70 {
  width: 70%;
}

.w30 {
  width: 30%;

  justify-content: flex-end;

  align-items: start;
}

.expTable .eachhh1 {
  display: flex;

  padding: 24px;

  width: 100%;
}

.abs {
  position: absolute;
}

::-webkit-input-placeholder {
  color: #b1b1b1 !important;
}

.megintop32 {
  margin-top: 32px;
}

.saving {
  margin: 0px;
  margin-top: 60px;
  display: flex;
}

.editing {
  padding: 8px 18px;
}

.customSelect h4 {
  text-transform: capitalize;
  border-radius: 7px;
  border: 1px solid #161d2f;
  background: #161d2f;
  padding: 12px 18px;
  margin: 0px;
  color: #b1b1b1;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.customSelect ul {
  padding: 0px;
  border-radius: 7px;
}

.customSelect li {
  background: #161d2f;
  padding: 12px 18px;
  margin: 0px;
  color: #b1b1b1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.arrowabs {
  position: absolute;
  right: 5%;
  top: 46%;
}

.defaulteach {
  width: 20%;
}

.checkbox.active::before {
  background: #b0d136 !important;
}

.checkbox.nonactive::before {
  background: transparent !important;
}

.boxCheck {
  position: relative;
  width: 18px;
  height: 18px;
  background: #2b2d32;
  margin: 0px 8px 0px 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 4px !important;
  cursor: pointer !important;
}

.boxCheck.active::before {
  position: absolute;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  top: 2px;
  left: 2px;
  content: "";
  width: 12px;
  height: 12px;
  transition: 120ms transform ease-in-out;
  background: #9dbc35;
  transform-origin: bottom left;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #b1b1b1 !important;
}

.serpro-eachinput .MuiInputBase-formControl {
  background: #212b46 !important;
}

.w23percent {
  width: 25%;
}

.cursorShow {
  cursor: pointer;
}

.wie50 {
  width: 50%;
  margin: 0px 10px;
}

.optimise {
  display: flex;
  gap: 48px;
}

.optimise label {
  color: #e8e8e8;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}

.optimise .run-checkbox {
  gap: 0px;
}

.optimiFlex {
  display: flex;
  gap: 12px;
  width: 50%;
}

.optimiFlex h2 {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}

.optimiFlex input {
  width: 100%;
}

.pri {
  color: #fff;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sec {
  color: #b0d136;
}

.strt {
  display: flex;
  justify-content: start;
}

.end {
  display: flex;
  justify-content: end;
}

.ter {
  color: #6a6e79;
}

.successGreen {
  color: #27be0e;
}

.failRed {
  color: #a61336;
}

.expList {
  width: 28%;
  height: 84dvh;
  overflow-y: scroll;
  padding: 16px;
  border: 1px solid #2b2d32;
  background: #0d1322;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 0px;
}

.statusexp img {
  margin-right: 4px;
  margin-left: 12px;
}

.statusexp {
  align-items: center;
}

.fl {
  display: flex;
}

.runblue {
  color: #0e8ebe;
}

.srchcn {
  top: calc(75% - 16px);
  position: absolute;

  color: #b1b1b1;
  right: 4%;
}

.expBox {
  display: flex;
  flex-direction: row;
  padding: 20px 16px;
  border-radius: 4px;
  border: 1px solid #2b2d32;
  cursor: pointer;
  background: #161d2f;
  position: relative;
}

.expBox:hover {
  background: #212b46;
}

.expList h4 {
  margin: 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.expList p {
  margin: 0px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dirCol {
  flex-direction: column;
}

.dirRow {
  flex-direction: row;
}

.conentExp {
  margin-top: 8px !important;
}

.creatBy {
  margin-top: 26px !important;
}

.inputSearchicon {
  position: absolute;
  top: 11px;
  right: 4px;
  color: #b1b1b1;
}

.expList input {
  border-radius: 4px;
  background: #212b46;
  color: #6a6e79;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 9px 12px;
}

.expList input:hover {
  background: #161d2f;
}

.tableChange {
  display: flex;
  gap: 4px;
}

.tableChange i {
  border-radius: 4px;
  background: #212b46;
  color: #b1b1b1;
  padding: 8px 12px;
  cursor: pointer;
}

.tableChange i:hover {
  background: #161d2f;
}

.expList {
  position: relative;
}

.fixinlist {
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  left: 2px;
  background: #0d1322;
  padding-top: 16px;
  padding-bottom: 8px;
  z-index: 99;
}

.logoutML {
  width: 40vw !important;
}

.expTable {
  width: 100%;
  height: 82dvh;
  overflow-y: scroll;
}

.expTable .eachhh {
  padding: 24px;
  width: 40%;
}

/* .expTable .eachhh1 {
  padding: 24px;
  /* width: 60%; 
} */

.tabhead {
  display: flex;
  /* justify-content: space-evenly; */
}

.tabhead h4 {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #212b46;
  width: 34%;
  text-align: center;
  padding: 12px 0px;
  margin: 0px;
  border-right: 1px solid #212b46;
}

.tabhead h4 i {
  margin-right: 8px;
  color: #fff;
}

.expBoxactive {
  background: #212b46;
}

.inHead {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1px;
  /* gap: 2px;
    padding: 0px 4px; */
}

.inHead h5 {
  text-align: center;
  padding: 12px 0px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #0d1322;
  margin: 0px;
  width: 100%;
  cursor: pointer;
}

.currentBlue {
  background: #212b46 !important;
}

.currentBluee {
  background: #212b46 !important;
}

.w75 {
  width: 75%;
}

.downloadAllonce svg {
  color: #5a5a5a;
}

.martop8 {
  margin-top: 8px;
}

.visuImg {
  width: 40%;
}

.visufull {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 24px;
  justify-content: center;
  align-items: center;
}

.consolefull {
  padding: 24px;
  background: #000;
  overflow: scroll;
}

.consoleEach {
  color: #6a6e79;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 14px;
}

.smallbtn {
  /* color: #FFF; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #212b46;
  color: #b1b1b1;
  padding: 11px 12px;
  cursor: pointer;
}

.smallbtn:hover {
  background: #161d2f;
}

.dialogAllprojects p {
  font-size: 14px;
}

.fle {
  display: flex;
}

.fleximgcreate {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  background: #0d1322;
  padding: 24px;
  align-items: center;
  justify-content: center;
  height: 50dvh;
  overflow-y: scroll;
}

.fleximgcreate img {
  width: 40%;
}

.greenTitle {
  color: #b0d136;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.marTop24 {
  margin-top: 24px;
}

.inpHyper {
  margin-top: 32px;
}

.mar0 {
  margin: 0px;
}

.allprojts {
  display: flex;
  flex-direction: column;
  padding: 80px;
}

.filteralign {
  display: flex;
  margin-left: 23px;
}

.tit {
  width: 100px;
}

.mybtn {
  border-radius: 4px;
  background-color: #212b46;
  display: flex;
  padding: 6px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
}

.mybtn:hover {
  opacity: 0.5;
}

.box {
  padding: 10px 36px 42px 36px;
  border-radius: 4px;
  border: 1px solid #2b2d32;
  background-color: #212b46;
  /* padding: 38px 36px 48px 36px; */

  width: 25%;
  height: auto;
  outline: #070d19;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
}

.hdr {
  color: #fff;

  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 30px !important;
  padding-top: 10px !important;
}

.inpt {
  border-radius: 4px;
  background-color: #161d2f;
  width: 100%;
  height: 47px;
  color: #6a6e79;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  margin: 5px;
  outline: none;
}

.inpt1 {
  border-radius: 4px;
  background-color: #161d2f;
  width: 100%;
  height: 47px;
  color: #b1b1b1;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  margin: 5px;
  outline: none;
  padding-left: 15px;
}

/* .inpt :hover{
      border-radius: 4px;
      background-color:  #161D2F;
      width: 100%;
      height: 47px;
      color:  #6A6E79;
      font-family: Nunito;
      font-size: 12px;
      font-style: normal;
      margin: 5px;
      outline: none ;
    } */
.MuiMenu-list .MuiMenuItem-gutters.create {
  border: 1px solid #2b2d32 !important;
  background: #212b46 !important;
  /* color: var(--header-text, #b1b1b1 !important; */
  color: #fff;
  /* table */
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
}

.MuiMenu-list .MuiMenuItem-gutters.opt {
  /* color: rgba(106, 110, 121, 0.48 !important; */
  color: #fff;
  /* header, */
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.prblkbtn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  padding: 3px 6px;
  margin-top: 15px;
}

.blkbtn {
  border-radius: 3px;
  background: #161d2f;
  /*   
  width: 84px;
  height: 28px; */
  padding: 6px 29px 8px 29px;
  justify-content: center;
  padding: 6px 12px;

  flex-shrink: 0;
  color: #fff;
  text-align: center;

  /* table */
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blkbtn:hover {
  opacity: 0.5;
}

.third {
  display: flex;
  flex-direction: column;

  margin: 20px;
  padding: 30px;
  gap: 10px;
}

.third_1 {
  display: flex;
  align-items: center;

  gap: 5px;
}

.name_1 {
  color: #b1b1b1;
}

.name1 {
  color: #6a6e79;

  /* Subject title-subheadline */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.name3 {
  color: #27be0e;

  /* Text */
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hdrnew {
  display: flex;
  justify-content: flex-end;
  padding: 0px 30px;
  margin: 20px 0px;
  align-items: center;
}

.commonbtn.savebtn {
  margin-left: 0px !important;
}

/* .c1 {
  display: flex;
  padding-left: 18px !important;
  flex-direction: row;
  border-bottom: none;
  background: #212b46 !important;
  gap: 15px;
  border: none !important;
  align-items: center;
} */

.tbhead {
  background: #212b46 !important;
  padding-left: 20px !important;
  border: none;
}

.posrel.wdth {
  width: 15%;
}

.r1 {
  /* display: flex; */
  padding-left: 4% !important;
  flex-direction: row;
  border-bottom: none;
  background: #070d19 !important;
  /* border-right: 2.7px solid #070d19 ; */
  gap: 15px;
  align-items: center;
}

.r1 span {
  margin-left: 4%;
}

.rd.point {
  cursor: pointer;
}

.rd {
  padding-left: 20px !important;
  border-bottom: 1px solid #27282a !important;
  background: #070d19 !important;
  /* border-right: 2.7px solid #070d19; */
  gap: 15px;
  align-items: center;
}

.clr {
  color: white;
}

.newclrrd {
  background: #161d2f !important;
  color: #6a6e79;
  /* border-right: 2px solid #070d19; */
  gap: 15px;
  align-items: center;
  padding-left: 20px !important;
  border-bottom: 1px solid #27282a;
}

.newclrrdarrow {
  background: #161d2f !important;
  color: #6a6e79;
  /* border-right: 2px solid #070d19; */
  gap: 15px;
  text-align: center;
  padding-left: 20px !important;
  border-bottom: 1px solid #27282a;
  cursor: pointer;
}

.newr1 {
  /* display: flex; */
  padding-left: 18px !important;
  flex-direction: row;
  border-bottom: none;
  background: #161d2f !important;
  /* border-right: 2px solid #070d19; */
  gap: 15px;
  align-items: center;
  color: #6a6e79;
}

.newr1 span {
  margin-left: 5%;
}

.mt60 .MuiPaper-elevation {
  margin-top: 60px;
  background-color: #161d2f;
  width: 45rem;
  padding-bottom: 72px;
  border-top: 1px solid rgba(106, 110, 121, 0.32);
  border-left: 1px solid rgba(106, 110, 121, 0.32);
}

.api {
  color: var(--icons, #b0d136);
  font-size: 16px;
}

.f1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #313747;
  padding: 10px 35px;
}

.f {
  margin-top: 60px;
}

.f2 {
  display: flex;
  flex-direction: row;
  margin-left: 40px;

  border-bottom: 1px solid rgba(106, 110, 121, 0.16);
  padding-bottom: 5px;
  padding-top: 10px;
}

.f2white {
  color: #fefefe;
  width: 30%;
  font-size: 16px;
}

.f2gray {
  color: #6a6e79;
  font-size: 16px;
  font-style: normal;
}

.descript {
  color: var(--icons, #b0d136);
  font-size: 16px;
  margin-left: 40px;
  margin-top: 36px;
  margin-bottom: 8px;
}

.desbox {
  border-radius: 5px;
  border: 1px solid #222839;
  margin-left: 40px;
  margin-right: 20px;
  color: #6a6e79;
  text-align: justify;
  padding: 10px 20px;
  font-size: 14px;
}

.deploybtn {
  border-radius: 4px;
  background: #212b46;
  color: #b1b1b1;
  font-size: 12px;
  text-align: center;
  margin-left: 40px;
  margin-top: 36px;
  padding: 6px 12px;
}

.deploybtn:hover {
  opacity: 0.5;
}

.movebtn {
  border-radius: 4px;
  background: #212b46;
  color: #b1b1b1;
  font-size: 12px;
  text-align: center;
  margin-left: 40px;
  margin-top: 36px;
  /* width: 180px;
      height: 30px; */
  padding: 6px 12px;
}

.movebtn:hover {
  opacity: 0.5;
}

.f3main {
  margin-top: 35px;
}

.f3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;

  padding-bottom: 5px;
  padding-top: 10px;
}

.f3white {
  color: #fefefe;
  width: 15%;
  font-size: 16px;
}

.f3box {
  border-radius: 6px;
  background: #1b2337;
  color: #6a6e79;
  width: 50%;

  justify-content: center;
  padding: 5px 10px;
}

.f3_new {
  margin-top: 35px;
  margin-left: 40px;
  color: #fefefe;
}

.popupProject .p-dialog-header {
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.popupProject .p-dialog-content {
  border-bottom-right-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

.trailsInput {
  background: #161d2f;
  border: 1px solid #161d2f;
  border-radius: 7px;
  color: #b1b1b1;
  padding: 9px 24px;
}

.mt34px {
  margin-top: 34px;
}

.mt100px {
  margin-top: 100px;
}

.stickyHead {
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
}

.imgVizData {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40%;
}

.ml52px {
  margin-left: 52px;
}

.padbot0px {
  padding-bottom: 0px !important;
}

.prophetflex {
  margin-top: 32px;
  display: flex;
}

.prophetflex input {
  padding: 10px 24px !important;
  color: #b1b1b1 !important;
  font-size: 14px !important;
  background: #161d2f;
}

.timeseriesPredict {
  margin-top: 32px;
  display: flex;
  gap: 24px;
  align-items: center;
}

.asso-input {
  display: flex;
  margin-top: 42px;
}

.asso-input {
  margin-top: 32px;
  display: flex;
}

.asso-input input {
  padding: 10px 24px !important;
  color: #b1b1b1 !important;
  font-size: 14px !important;
  background: #161d2f;
}

.asso-input label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 8px;
}

.addInputBtn {
  height: 36px;
  padding: 8px 16px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  background: #212b46;
  margin-top: 32px;
  cursor: pointer;
}

.flexWrap {
  flex-wrap: wrap;
}

.mt32 {
  margin-top: 32px;
}

.inputAbsFileUpload {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
}

.uploadFileText {
  color: #b1b1b1 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  margin: 0px;
  position: absolute;
  top: 30%;
  left: 5%;
  cursor: pointer;
}

.fileUploadServing {
  height: 43px;
  background: #161d2f;
}

.MuiInputBase-root:hover {
  background: #212b46 !important;
}

.layersWrap {
  display: flex;
  flex-wrap: wrap;
}

.MuiMenu-list li,
.MuiSelect-select {
  text-transform: capitalize !important;
}

.fileNameUpload {
  color: #b0d136;
  margin-left: 20px;
  margin-top: 12px;
  font-size: 14px;
}

.margin-top-100px {
  margin-top: 100px;
}

.run-check {
  margin-top: 70px;
}

.threeDots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

td {
  max-width: 100px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.css-q8hpuo-MuiFormControl-root {
  align-items: center;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 100%;
}

.industry {
  width: 80%;
  border-radius: 8px 8px 8px 8px;
  background: #161d2f;
  color: #b1b1b1;
  padding: 1%;
  margin-top: 4%;
  margin-left: 1.5%;
  font-size: 15px;
}

.innerindustry {
  background: #161d2f;
  color: #b1b1b1;
  padding: 1% !important;
  border-radius: 2px 2px 8px 8px;
  border-bottom: 0.5px solid #b1b1b1;
}

.innerindustry:hover {
  background: #212b46 !important;
}

.IndustryHeader {
  color: #b0d136;
  padding-top: 4%;
}

.darrow {
  position: relative;
  right: 5%;
}
