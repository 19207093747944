/* .main {
  height: 892px;
} */
.feature {
  padding: 15px 7px;
  background: #212b46;
  margin-bottom: 8px;
}
.feature h4 {
  color: #b0d136;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.nullip {
  background: #0d1322;
  color: #6a6e79;
  padding: 1.5%;
  margin-left: 6%;
  font-weight: 800;
}

.accordionheader {
  margin-bottom: 0px !important;
  border-bottom: 4px solid #161D2F !important;
  border-right: 6px solid #161D2F !important;
  border-left: 6px solid #161D2F !important;
}
