.deepFull {
    margin-top: 32px;
}

.addLayer {
    margin-bottom: 28px;
    border-radius: 3px;
    border: 1px solid #2B2D32;
    background: #161d2f;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 12px;
    cursor: pointer;
    margin-right: 42px;
}

.addLayer:hover {
    background: #212B46 !important;
}

.end {
    display: flex;
    justify-content: flex-end;
}

.layerFullData {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.info1 {
    display: flex;
    align-items: center;
}

.fle {
    display: flex;
    align-items: center;
    gap: 12px;
}


.optimiFlex1 {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-bottom: 36px;
}


.optimiFlex h2, .optimiFlex1 h2 {
    color: #b1b1b1;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4px;
}


.run-checkbox {
    align-items: center;
    /* margin-top: 70px; */
}