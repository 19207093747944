.tabel {
  display: flex;
  flex-direction: column;
  padding: 1% 0% 0% 5%;
  gap: 4%;
}

.metricss {
  color: #ffffff;
  padding-left: 0.8%;
  padding-bottom: 1%;
}

.datacolor {
  color: #b1b1b1 !important;
}
